/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, b, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
    overscroll-behavior: none;
}

body {
  line-height: 1;
  overflow-x: hidden;
  overflow-y: hidden;
  font-family: 'DM Sans', sans-serif;
  letter-spacing: -0.5px;
  scroll-behavior: smooth;
}

/* html, body {
  scroll-snap-type: y mandatory;
} */

ol, ul {
	list-style: none;
}

blockquote, q {
	quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}

table {
	border-collapse: collapse;
	border-spacing: 0;
}

#webgi-canvas {
  width: 100%;
  height: 100vh;
  margin: 0;
  padding: 0;
  position: fixed;
  top: 0;
  left: 0;
  touch-action: none;
  /* pointer-events: none; */
}

#webgi-canvas-container{
  width: 100vw;
  height: 100vh;
  margin: 0;
  padding: 0;
  position:fixed;
}

/* LOADER */
.loader {
  display: flex;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 10;
  background-color: #e1dedf;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 2em;
  font-size: 12px;
}

.progress {
  position: absolute;
  top: 60%;
  width: 50%;
  height: 1px;
  background: #c42323;
	transform: scaleX(0);
	transition: transform 1s;
  transform-origin: left;
	z-index: 10;
  margin: 0 5%;
}

/* HEADER SECTION */
.header{
  position: absolute;
  z-index: 2;
  display: flex;
  width: 100%;
  justify-content: center;
}

.header--container{
  display: flex;
  width: 80vw;
  justify-content: space-between;
  align-items: center;
  margin-top: 40px;
  opacity: 0;
}

.header--brand{
  width: 140px;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.header--menu {
  display: inline-flex;
  gap: 1em;
  align-items: center;

}

.header--menu li{
  padding: 10px 30px;
  color: black;
  transition: all .6s ease-in-out;
  border-radius: 50px;
  pointer-events: all;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.header--menu li:hover{
  padding: 10px 30px;
  background: black;
  color: white;
  cursor: pointer;
}

/* HERO SECTION */

.section{
  position: relative;
  z-index: 1;
  display: flex;
  width: 100%;
  height: 100vh;
  align-content: center;
  justify-content: center;
  margin: 0;
  padding: 0;
  scroll-snap-align: start;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.hero--container{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 80%;
}

.hero--content{
  width: 320px;
  position: absolute;
  margin-left: 10%;
  opacity: 0;
}

h1{
  position: relative;
  left: -12px;
  width: 280px;
  height: 76px;
  font-style: normal;
  font-weight: 700;
  font-size: 184px;
  line-height: 76px;
  letter-spacing: -0.01em;
  color: #181818;
  margin-bottom: 60px;
}

.hero--content > h2{
  text-align: right;
  margin-right: 56px;
  margin-bottom: 9px;
  font-weight: 500;
  font-size: 17px;
}

.hero--content > p{
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.04em;
  margin-bottom: 30px;
}

.button{
  width: 194px;
  height: 60px;
  left: 134px;
  top: 447px;
  border: none;
  background-image: linear-gradient(9deg, #af0d0d, #ff9090);
  border-radius: 58px;
  display: inline-flex;
  align-items: center;
  justify-content: space-evenly;
  font-weight: 700;
  font-size: 18px;
  display: flex;
  align-items: center;
  letter-spacing: -0.02em;
  color: #FFFFFF;
  padding: 10px;
  transition: all 0.8s ease-in-out;
  background-position: 0 -30px;
  background-size: 100% 90px;
  background-repeat: repeat-x repeat-y;
}

.button:hover{
  background-image: linear-gradient(9deg, #af0d0d, #ff9090);
  box-shadow: 0px 6px 20px #d50f0fc3;
  transform: translateY(-3px);
  cursor: pointer;
  background-position: 0 0;
}


.bounce {
  -moz-animation: bounce 2s infinite;
  -webkit-animation: bounce 2s infinite;
  animation: bounce 2s infinite;
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-15px);
  }
  60% {
    transform: translateY(-5px);
  }
}

.hero--scroller--container{
  bottom: 0;
  align-items: center;
  width: 170px;
  align-content: center;
  flex-direction: column;
  position: absolute;
  text-align: center;
  line-height: 3.2em;
  color: grey;
  overflow: hidden;
}

.hero--scroller{
  opacity: 0;
}

/* PERFORMANCE SECTION */

.performance--container{
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 80%;
}

.performance--content{
  width: 320px;
  position: absolute;
  margin-right: 10%;
  opacity: 0;
}

.performance--content p{
  line-height: 22px;
}

.performance--container.fixed{
  position: fixed;
  top: 50%
}

.performance--content > h1{
  position: relative;
  left: -4px;
  font-style: normal;
  font-weight: 700;
  font-size: 85px;
  line-height: 55px;
  letter-spacing: -0.01em;
  color: #be1921;
  margin-bottom: 60px;
  word-break: break-all;
}

.performance--content > h2 {
  margin-bottom: 16px;
  font-weight: 500;
  font-size: 17px;
}

.performance--content > img{
  margin-top: 32px;
  margin-bottom: 32px;
  border-radius: 10px;
}

/* POWER SECTION */

.power--container{
  display: flex;
  width: 80%;
  align-content: flex-end;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-end;
}

.power--content{
  width: 400px;
}

.power--content > h1{
  font-style: normal;
  font-weight: 700;
  font-size: 125px;
  line-height: 55px;
  letter-spacing: -0.01em;
  color: #be1921;
  margin-top: 32px;
  margin-bottom: 24px;
}

.power--content > p{
  line-height: 22px;
}

.power--img{
  position: relative;
  top: -340px;
}

.power--features--img{
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 10%;
}

/* AUTOFOCUS SECTION */

.autofocus--container{
  display: flex;
  align-content: flex-end;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}

.autofocus--content{
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: 80px;
}

.autofocus--content p{
  width: 70%;
  text-align: center;
  line-height: 22px;
}

.autofocus--content > h1{
  font-style: normal;
  font-weight: 100;
  font-size: 85px;
  text-align: center;
  line-height: 55px;
  letter-spacing: -0.01em;
  /* margin-top: 32px; */
  margin-bottom: 24px;
  width: 100%;
}

.autofocus--content > h1 strong{
  font-weight: 700;
  color: #be1921;
}

.autofocus--img{
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 10%;
}

/* EXPLORE SECTION */

.explore--container{
  display: flex;
  align-items: flex-end;
  justify-content: center;
  align-content: flex-end;
  flex-direction: column;
  width: 100%;
}

.explore--content{
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-end;
  margin-right: 10%;
}

.explore--content p{
  width: 70%;
  text-align: center;
  line-height: 22px;
}

.explore--content > h1{
  font-style: normal;
  font-weight: 100;
  font-size: 85px;
  text-align: center;
  line-height: 75px;
  letter-spacing: -0.01em;
  /* margin-top: 32px; */
  margin-bottom: 94px;
  width: 100%;
  width: 400px;
  text-align: right;
}

.explore--content > h1 strong{
  font-weight: 700;
  color: #be1921;
}

.exit--container{
  display: none;
  width: 100%;
  height: 100%;
  align-content: center;
  bottom: 60px;
  align-items: flex-end;
  position: fixed;
  justify-content: center;
  z-index: 3;
  pointer-events: none;
}

.button--secondary{
    border-radius: 58px;
    font-weight: 700;
    font-size: 10px;
    display: flex;
    align-items: center;
    letter-spacing: -0.02em;
    color: #3d3d3d;
    transition: all 0.4s ease-in-out;
    background-repeat: repeat-x repeat-y;
    z-index: 2;
    padding: 10px 30px;
    border: 1px solid black;
    text-transform: uppercase;
    pointer-events: auto;
    margin-right: 10px;
}

.button--secondary:hover{
  cursor: pointer;
  color: #ffffff;
  background-color: #181818;
  pointer-events: auto;
}

/* MOBILE ADJUSTMENTS */
@media (max-width: 600px) {

  .hero--scroller--text{
    display: none;
  }

  .header--menu{
    display: none;
  }

  .header--container{
    justify-content: center;
  }

  .hero--container{
    display: flex;
    align-content: center;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    width: 100% !important;
  }

  .hero--content{
    width: 100%;
    margin-left: 0;
    position: absolute;
    align-items: center;
    display: flex;
    flex-direction: column;
    align-content: center;
    /* opacity: 0; */
  }

  h1{
    left: unset;
    width: unset;
    height: unset;
    font-size: 7em;
    line-height: 1em;
    letter-spacing: -0.01em;
    margin-bottom: 30vh;
    margin-top: -10px;
    position: relative;
  }

  .hero--content > h2{
    text-align: center;
    margin-bottom: 9px;
    font-weight: 500;
    font-size: 1em;
    margin-right: 0;
    margin-top: 40px;
  }

  .hero--content > p{
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: -0.04em;
    margin-bottom: 30px;
    width: 85%;
    text-align: center;
  }

  .performance--container {
    position: absolute;
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }

  .performance--content {
    width: 320px;
    position: absolute;
    margin-right: 0;
    opacity: 0;
    margin-top: 20%;
    text-align: center;
  }

  .performance--content p {
    display: none;
  }

  .performance--content img {
    display: none;
  }

  .performance--content svg {
    margin-top: 50vh;
  }

  .power--content {
    margin-top: 100vh;
  }

  .power--content p{
    width: 85%;
  }

  .power--content h1{
    font-size: 6em;
  }

  .power--container{
    display: flex;
    width: 80%;
    align-items: flex-start;
    flex-direction: column;
    justify-content: space-around;
  }

  .power--features--img {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 2%;
  }

  .power--features--img img {
    width: auto;
    height: 220px;
  }

  .autofocus--content h1{
    font-size: 3em;
    line-height: 1em;
    margin-bottom: 60vh;
  }

  .autofocus--content{
    margin-top: 30px;
  }

  .explore--container{
    align-items: center;
    align-content: flex-end;
    justify-content: flex-end;
  }

  .explore--content {
    align-items: center;
    margin-right: 0;
    margin-bottom: 40px;
  }

  .explore--content h1{
    text-align: center;
    font-size: 3em;
    line-height: 1em;
    margin-bottom: 40px;
  }

  

}
